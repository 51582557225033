@import '../../styles/Variables'

div.invoice-form
    display: flex
    flex-direction: column
    gap: .4rem
    margin-bottom: .4rem
    border: 2px solid #aaaaaa
    border-radius: 8px 
    padding: .4rem

    div.form-row 
        display: flex
        flex-wrap: wrap
        gap: 0.1rem

        div.form-cell 
            text-align: left

            &.label
                flex: 1 0 6rem

            &.value
                flex: 10 0 10rem

div.total-amount
    font-size: 1.5rem
    font-weight: bold
    color: $strong-text-color
    background-color: $strong-background-color
    border: 2px solid #aaaaaa
    border-radius: 8px
    padding: 0.5rem 1rem
    text-align: center
    margin-top: 1rem
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
