section
  display: flex
  justify-content: center
  align-items: center
  min-height: 100vh
  background: url('./LocEM_HD_grad_fond_transparent.png') no-repeat
  background-size: contain
  background-position: center

  .wrapper
    width: 420px
    transform: translateY(-20%)
    background: transparent
    border: 2px solid rgba(48, 48, 48, .2)
    backdrop-filter: blur(20px)
    box-shadow: 0 0 10px rgba(0, 0, 0, .1)
    color: #fff
    border-radius: 10px
    padding: 30px 40px



    h1
      font-size: 30px
      text-align: center
      color: #333

    .input-box
      position: relative
      width: 100%
      height: 50px
      margin: 30px 0


      input
        width: 100%
        height: 100%
        background: transparent
        border: none
        outline: none
        border: 2px solid rgba(48, 48, 48, .2)
        border-radius: 40px
        font-size: 16px
        color: #333
        padding: 20px 45px 20px 20px

        &::placeholder
          color: #333

      .svg-inline--fa
        position: absolute
        right: 20px
        top: 50%
        transform: translateY(-50%) 
        font-size: 20px
        color: #333
    
    button
      width: 100%
      height: 45px
      background: #ccc
      border: none
      outline: none
      border-radius: 40px
      box-shadow: 0 0 10px rgba(0, 0, 0, .4)
      cursor: pointer
      font-size: 16px
      color: #333
      font-weight: 600
