.upload-status {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .upload-status.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .upload-status.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .dropzone {
    border: 2px dashed #ddd;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .dropzone.active {
    border-color: #0056b3;
  }
  
  .upload-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload-button:disabled {
    background-color: #ccc;
  }
  