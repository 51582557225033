span.bat-high
  color: SeaGreen

span.bat-medium
  color: Tomato

span.bat-low
  color: Crimson
  font-weight: bold

@media screen and (max-width: 767px)
    #devices-table, #hc-planning-table

        width: 100%

        thead
            display: none
        .not-media
            display: none
          
#devices-table 

    .not-screen
        display: none

@media screen and (max-width: 767px)
    #devices-table
        .not-screen
            display: table-cell