nav.navbar
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  padding: 1rem
  position: fixed
  width: 100%
  min-height: 75px
  background-color: #333
  color: #ddd

  ul.nav-links
    display: flex

    span.nav-label
      padding-left: 0.4rem

    a
      color: #ddd
      padding: 0 0.5rem
    
  .nav-burger
    width: 40px
    height: 40px
    background: transparent
    border: none
    color: inherit

@media screen and (max-width: 1024px)
  nav.navbar
    span.nav-label
      display: none


@media screen and (max-width: 767px)
  nav.navbar

    span.nav-label
      display: inline

    ul.nav-links
      flex-direction: column
      justify-content: start
      align-items: left
      position: fixed
      left: -100vw
      top: 2rem
      /* height: 100vh */
      width: 0
      background-color: inherit
      padding: 2rem
      visibility: hidden
      transition: all .5s ease-in

      a
        display: block
        padding: 0.5rem
        font-size: 5vw

    .nav-burger
      display: block
      position: fixed
      right: 1rem
      top: 1rem
      &:hover
        cursor: pointer

      .burger-line 
        &, &::before, &::after
          display: block
          width: 40px
          height: 3px
          position: relative
          border-radius: 3px
          background: #DDD
          transition: all .5s ease-in-out
        &::before, &::after
          content: ""
          position: absolute
          left: 0
        &::before
          transform: translateY(-12px)
        &::after
          transform: translateY(12px)

    &.show-nav 
      ul.nav-links 
        left: 0
        right: 0
        width: 90vw
        visibility: visible
      .nav-burger

        .burger-line 
          background: transparent
          width:0
          &::before
            transform: rotate(45deg)
          &::after
            transform: rotate(-45deg)

@media print
  nav.navbar
    display: none