div.hc-table
    display: flex
    flex-direction: column
    gap: .4rem

    div.hc-row 
        display: flex
        flex-wrap: wrap
        gap: 0.1rem
        border: 2px solid #aaaaaa
        border-radius: 8px 

        div.hc-cell 
            padding: 3px
            flex: 1 0 10rem
            text-align: center

            &.date
                text-align: left
                padding-left: 1rem
            
            &.reloue, &.no-hc, &.report, &.done
                flex: 1 0 3rem


            