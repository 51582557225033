@media screen and (max-width: 767px)
    .hk-table 

        width: 100%

        thead
            display: none

        tr
            display: block
            margin-bottom: 1em

        td
            display: block
            text-align: right
            border: 0px none
            &.not-media
                display: none
            &:before
                content: attr(data-label)
                float: left
                font-weight: bold




.hk-table

    .not-screen
        display: none

    td.c
        text-align: center

    .prop-span
        cursor: pointer
        &:hover
            background: #4C4

@media screen and (max-width: 767px)
    .hk-table 
        .not-screen
            display: block