@media screen and (max-width: 767px)
  .checkin-table 
    width: 100%
    font-size: 1.1em /* Augmentation de la taille de la police */
    border-collapse: separate /* Ajout de la propriété border-collapse */
    border-spacing: 0 /* Définition de l''espacement des bordures */

    thead
      display: none

    tr
      display: block
      margin-bottom: 1em
      border-radius: 8px 
      border: 1px solid #ddd
      &:hover
        td
          background-color: inherit /* Réinitialisation de la couleur de fond au survol */

    td
      display: block
      text-align: right
      border: 0px none
      padding: 0.2em .5em

      &:nth-child(1), &:nth-child(2) 
        text-align: center
      
      &.not-media
        display: none

    td:before
      content: attr(data-label)
      float: left
      font-weight: bold


.checkin-table
    tr.del
        &, *
            color: LightSlateGray
            text-decoration-line: line-through
            
    tr.new
        &, *
            font-style: italic
            color: LightSlateGray