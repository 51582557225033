#subnavbar
    margin-bottom: .5em 
    display: flex
    flex-wrap: wrap

    ul 
        display: flex
        flex-wrap: wrap
        
        li
          margin: .25em 0em

          a
              padding: .2em 0.8em
              margin: .1em .2em
              color: #333
              border-radius: 1em

              &:hover
                  background: #dec

              &.active
                  background: #9c9

                  &:hover
                      background: #7a7

@media print
  #subnavbar
    display: none
  h2
    display: none