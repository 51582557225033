.popup
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    backdrop-filter: blur(2px)
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)

    .popup-inner
        position: relative
        padding: 32px
        width: 100%
        max-width: 640px
        background-color: #FFF
        border: 2px solid rgba(48, 48, 48, .2)
        box-shadow: 0 0 10px rgba(0, 0, 0, .1)
        border-radius: 10px

        .close-btn
            position: absolute
            top: 16px
            right: 16px
            background: none
            border: none
            cursor: pointer
            padding: 0

            &:focus
                outline: none

            &:hover .close-icon
                color: red
                transform: scale(1.1)

        .close-icon
            font-size: 24px
            color: rgba(141, 20, 20, 0.7)
            transition: color 0.3s, transform 0.3s
