@import url(https://fonts.googleapis.com/css?family=Roboto:400,700)
@import 'Variables'

*
  margin: 0
  padding: 0

  box-sizing: border-box
  outline: none

body
  font: 100% Roboto, sans-serif
  color: #000

ul
  list-style: none
  margin: 0
  padding: 0

a
  text-decoration: none
  color: inherited

div.root
  display: flex

main.main
  min-height: 100vh
  padding: 6rem 1rem 1rem 1rem
  background-color: #eee
table
  border-collapse: separate
  border-spacing: 0
  width: 100%

  tr
    &:hover td
      background-color: #a9cce3
    &:nth-child(even):not(.today)
      background-color: #ecf0f1
    &:nth-child(odd):not(.today)
      background-color: #d5e8d4
    &.today:nth-child(even)
      font-weight: bold
      background-color: #a5c391
    &.today:nth-child(odd)
      font-weight: bold
      background-color: #b3cf81


  a
    color: #2c3e50
    transition: color 0.3s ease

    &:hover
      color: #3498db
      text-decoration: underline
      text-decoration-style: dashed

  th
    background-color: $strong-background-color
    color: $strong-text-color
    font-weight: bold
    padding: 10px
    text-align: left

  td, th
    border: 1px solid #ddd
    padding: 8px


@media print
  nav
    display: none

  main.main
    min-height: 0
    padding: 0

  body
    font-size: 10pt

  table
    width: 100%